import React, { Component } from 'react'
// import LazyLoad from 'react-lazyload'
import styled from 'styled-components'

const Figure = styled.figure`
  width: 100%;
  transition: 0.3s ease-out opacity;
  transition-delay: 0.4s;
  img {
    width: 100%;
    vertical-align: middle;
    transition:0.1s ease-out opacity;
  }
  img.loaded {
    opacity: 1;
  }
`

export default class Image extends Component {
  componentDidMount() {
    // this.img.onload = () => {
    //   this.img.classList.add('loaded')
    // }
  }

  render() {
    const { src,
      style = '',
      alt = '',
      mobileSrc = false,
      height = null,
      width = null
    } = this.props


    return (
      <Figure style={{ style }}>
        <picture>
          {mobileSrc && (
            <>
              <source media="(max-width: 750px)" srcSet={mobileSrc} type="image/jpeg" />
              <source media="(min-width: 751px)" srcSet={src} type="image/jpeg" />
            </>
          )}

          <img height={height} width={width} loading="lazy" src={src} alt={alt} />
        </picture>
      </Figure>
    )
  }
}
