import React from "react";
import styled from "styled-components";

import Image from "./Image";

const StyledSocialMedia = styled.div`
  margin-bottom: 1rem;
  display: flex;
  align-items: center;

  a {
    display: block;
  }

  @media (max-width: 600px) {
    flex-wrap: wrap;
    margin-bottom: 0;

    a {
      margin-bottom: 1rem;
    }
  }
`;
const Link = styled.div`
  width: 1.56rem;
  margin-right: 1rem;
`;

const SocialMedia = ({ show }) => {
  const shouldRender = channel => {
    if (!show) {
      return true;
    }

    return show.includes(channel);
  };

  return (
    <StyledSocialMedia className="social-media">
      {shouldRender("discord") && (
        <a
          href="https://skyweaver.chat"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Link className="link">
            <Image src="/images/discord.png" alt="Discord Logo" />
          </Link>
        </a>
      )}

      {shouldRender("twitter") && (
        <a
          href="https://twitter.com/SkyweaverGame"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Link className="link">
            <Image src="/images/twitter.png" alt="twitter Logo" />
          </Link>
        </a>
      )}

      {shouldRender("instagram") && (
        <a
          href="https://www.instagram.com/skyweavergame/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Link className="link">
            <Image src="/images/instagram.png" alt="Instagram Logo" />
          </Link>
        </a>
      )}

      {shouldRender("medium") && (
        <a
          href="https://medium.com/horizongames"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Link className="link">
            <Image src="/images/medium.png" alt="medium Logo" />
          </Link>
        </a>
      )}

      {shouldRender("reddit") && (
        <a
          href="https://www.reddit.com/r/Skyweaver/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Link className="link">
            <Image src="/images/reddit.png" alt="Reddit Logo" />
          </Link>
        </a>
      )}

      {shouldRender("facebook") && (
        <a
          href="https://www.facebook.com/SkyweaverOfficial/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Link className="link">
            <Image src="/images/facebook.png" alt="Facebook Logo" />
          </Link>
        </a>
      )}

      {shouldRender("twitch") && (
        <a
          href="https://www.twitch.tv/skyweaverlive"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Link className="link">
            <Image src="/images/twitch.png" alt="Twitch Logo" />
          </Link>
        </a>
      )}

      {shouldRender("youtube") && (
        <a
          href="https://www.youtube.com/channel/UClFG7LMrK6icGVyCpNDJlVQ/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Link className="link">
            <Image src="/images/youtube.png" alt="Youtube Logo" />
          </Link>
        </a>
      )}

      {/* {shouldRender("telegram") && (
        <a
          href=" https://web.telegram.org/#/im?p=@skyweaver"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Link className="link">
            <Image src="/images/telegram.svg" alt="Telegram" />
          </Link>
        </a>
      )} */}
    </StyledSocialMedia>
  );
};

export default SocialMedia