import React from "react";

import styled from "styled-components";
// import document from "global/document";

import data from "../../data";

import Nav from "./Nav";
import JoinDiscord from "./JoinDiscord";
// import BackToApp from "./BackToGame";
import TwitchInfo from "./TwitchInfo";
import PlayFreeButton from "./PlayFreeButton";
// import PlayButton from "./PlayButton";
import MobileHeader from "../MobileHeader";
import { DOWN, useScroll } from "utils/useScroll"


const BackgroundButton = styled.div`
  position: fixed;
  top: 0;
  right: 0rem;
  padding: 1.5rem;
  z-index: 49;

  a {
    width: auto;
  }
`

const Header = styled.header`
  padding: 1.5rem;
  width: 100%;
  position: fixed;
  top: ${({ cameFromGame }) => (cameFromGame ? "54px" : 0)};
  left: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: top;
  z-index: 50;

  background: ${({ showBg }) => (showBg ? "var(--background)" : "transparent")};
  border-bottom: 1px solid
    ${({ showBg }) => (showBg ? "var(--darkBorder)" : "transparent")};

  transform: ${({ direction }) =>
    direction === DOWN ? "translateY(-100%)" : "translateY(0)"};

  transition: 0.3s ease-out background, 0.3s ease-out border-color,
    0.3s ease-out transform;

  [aria-current] {
    position: relative;
  }
  [aria-current]::after {
    content: " ";
    border-bottom: 1px solid var(--lilac);
    position: absolute;
    bottom: 0;
    width: calc(100% - 1.5rem);
    left: 50%;
    transform: translateX(-50%);
    transition: 0.3s ease-out opacity;
  }
  .light-blue-active[aria-current]::after {
    border-color: #52ffff;
  }

  a[aria-current]:hover::after {
    opacity: 0.5;
  }
  .no-aria [aria-current]::after {
    display: none;
  }

  @media (max-width: 1000px) {
    display: none;
  }
`;

const HeaderButtons = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: flex-end;
`;




const HeaderComponent = (props) => {
  const { routeToHomepage } = props
  const { direction, showBg } = useScroll()


  return (
    <>
      <BackgroundButton>
        <PlayFreeButton />
      </BackgroundButton>
      <Header direction={direction} showBg={props.enforceBackground || showBg}>
        <Nav routeToHomepage={routeToHomepage} routes={data.routes} />

        <HeaderButtons>
          <TwitchInfo />
          <JoinDiscord content="Discord" />
          <PlayFreeButton />

          {/* <PlayButton /> */}
        </HeaderButtons>
      </Header>

      <MobileHeader
        showBg={showBg}
        routeToHomepage={routeToHomepage} />
    </>
  );
}

export default HeaderComponent