import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import { Button } from '../Typography'

import { Menu, MenuList, MenuButton, MenuItem } from '@reach/menu-button'
import '@reach/menu-button/styles.css'

const NavStyle = styled.nav`
  display: flex;
  align-items: center;
  text-transform: uppercase;
  z-index: 3;

  .nav-item {
    padding: 0.25rem 0.75rem;
    cursor: pointer;
    transition: 0.3s ease-out opacity;
    transform: translateY(-0.16rem);

    &:hover {
      opacity: 0.6;
    }
  }
`

const Icon = styled.div`
  margin-right: 1rem;
  width: 2rem;

  img {
    width: 100%;
  }
  transition: 0.3s ease-out opacity;
  &:hover {
    opacity: 0.6;
  }
`

const Indicator = styled.div`
  background: #ffd600;
  box-shadow: 0px 0px 8px #ff9900, 0px 0px 8px #ff9900;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: absolute;
  top: -1px;
  right: -2px;
`

const src = '/images/skyweaver-symbol.svg'

export const communityNavLinks = [
  { title: 'Way of the Weaver', url: '/community/way-of-the-weaver' },
  { title: 'Community Hall Of Fame', url: '/community/community-hall-of-fame' },
  { title: 'Creators Program', url: '/community/creators-program' },
  { title: 'Social Feed', url: '/community/social-feed' },
]

export const mediaNavLinks = [
  { title: 'Screenshots', url: '/media/screenshots' },
  { title: 'Wallpapers', url: '/media/wallpapers' },
  { title: 'Videos', url: '/media/videos' },
  { title: 'Media Kit', url: '/media/media-kit' },
]

export const expansionNavLinks = [
  { title: 'Hexbound Invasion', url: '/hexbound-invasion' },
  { title: 'Clash of Inventors', url: '/clash-of-inventors' },
]

const NavList = ({ title, navLinks, indicator = false }) => {
  return (
    <Menu>
      <MenuButton>
        <Button
          style={{
            '--currentColor': 'red',
            position: 'relative',
          }}
          className="nav-item"
        >
          {title}
          {indicator && <Indicator />}
        </Button>
      </MenuButton>
      <MenuList className="slide-down">
        {navLinks.map(link => (
          <MenuItem onSelect={() => {}} key={link.title}>
            <Link to={link.url}>
              <Button className="nav-item">{link.title}</Button>
            </Link>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  )
}

const Nav = ({ routes, routeToHomepage }) => {
  return (
    <NavStyle>
      <Icon className="no-aria">
        <Link to="/">
          <img src={src} alt="Skyweaver Icon" />
        </Link>
      </Icon>

      <Link to="/how-to-play">
        <Button className="nav-item">How To Play</Button>
      </Link>
      <Link to="/get-started">
        <Button className="nav-item">Get Started</Button>
      </Link>

      <a
        href="https://play.skyweaver.net/market/cards/buy"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button className="nav-item">Cards</Button>
      </a>

      <Link to="/news">
        <Button className="nav-item">News</Button>
      </Link>

      <NavList title="Community" navLinks={communityNavLinks} />

      <Link to="/lore">
        <Button className="nav-item">Lore</Button>
      </Link>

      <NavList title="Media" navLinks={mediaNavLinks} />

      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://support.skyweaver.net"
      >
        <Button className="nav-item">Support</Button>
      </a>

      <NavList title="Expansions" navLinks={expansionNavLinks} indicator />
    </NavStyle>
  )
}

export default Nav
