import React from 'react'
import styled from 'styled-components'

import { Sub } from '../Typography'
import SocialMedia from '../SocialMedia'

const Section = styled.div`
  width: 15rem;
  z-index: 3;

  @media (max-width: 750px) {
    width: 100%;
    order: 2;
    margin-top:3rem;
    padding-left: 1rem;
  }
`

const LeftContent = () =>
  <Section className='left-content'>
    <SocialMedia />

    <Sub>
      <a href='mailto:hello@skyweaver.net' className='footer__link'>
        hello@skyweaver.net</a>
    </Sub>

    <br />

    <Sub>
      <a href='/terms-of-service' className='footer__link'>Terms</a> | <a href='https://horizon.io/privacy' className='footer__link'>Privacy Policy</a>
    </Sub>

    <br />

    <Sub>
      © 2022 Horizon Blockchain Games Inc. All rights reserved.
    </Sub>

  </Section>

export default LeftContent
