import React from 'react'
import styled from 'styled-components'

import { Sub } from '../Typography'
import Image from '../Image'

const Section = styled.div`
  width: 15rem;
  text-align: right;

  @media (max-width: 750px) {
    width: 50%;
    order: 3;
    padding-right: 1rem;
  }
`
const Horizon = styled.div`
  width: 11rem;
  display: inline-block;
  margin-top: -2rem;
`

const RightContent = () =>
  <Section>
    <Sub>
      Skyweaver is created by
    </Sub>

    <br />

    <a href='https://horizon.io' target='_blank' rel='noopener noreferrer'>
      <Horizon>
        <Image src='/images/horizon.png' alt='Horizon Logo' />
      </Horizon>
    </a>
  </Section>

export default RightContent