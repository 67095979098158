import React, { useState } from 'react'
import { Dialog as ReachDialog } from '@reach/dialog'
import styled from 'styled-components'
import { Link } from 'gatsby'

import Toggle from './Toggle'
import { H3 } from '../Typography'
import JoinDiscord from '../Header/JoinDiscord'
import LeftContent from '../Footer/LeftContent'
import TwitchInfo from '../Header/TwitchInfo'
import { communityNavLinks, expansionNavLinks, mediaNavLinks } from 'components/Header/Nav'

import '@reach/dialog/styles.css'

const Header = styled.div`
  position: fixed;
  top: 0rem;
  right: 0rem;
  height: calc(100vh);
  width: 100%;
  z-index: 55;
  padding: 1rem;
  padding-top: 6rem;
  background-color: var(--dark);
  overflow: scroll;
  -webkit-filter: drop-shadow(0 0 2px #0c061e);
  filter: drop-shadow(0 0 2px #0c061e);
  transition: 0.3s ease-out transform, 0.3s ease-out opacity;
  transform: translateX(100%);
  opacity: 0;

  .join-discord,
  .playBtn {
    background-color: var(--lilac);
    margin: 0rem auto;
    margin-bottom: 1rem;
    height: 50px;
    display: flex;
    align-items: center;
  }

  .playBtn {
    margin-top: 3rem;
  }

  .join-discord {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .join-discord span,
  .playBtn span {
    color: var(--dark);
    font-size: 1.5rem;
  }

  .join-discord .discord-logo {
    width: 2rem;

    svg {
      fill: var(--dark);
    }
  }

  .left-content {
    padding-left: 0;
    p {
      font-size: 1.1rem;
    }
  }

  @media (max-width: 1000px) {
    ${({ closed }) =>
      !closed ? 'transform: translateX(0); opacity: 0.96;' : ''};

    .join-discord {
      margin-bottom: 1rem;
    }
  }
`

const NavItemStyle = styled.div`
  width: 100%;
  cursor: pointer;
  padding: 1rem 0.3rem;
  border-bottom: 1px solid var(--lilacLowOpacity);

  &:first-of-type {
    border-top: 1px solid var(--lilacLowOpacity);
  }

  h3 {
    font-size: 1.56rem;
    color: var(--lilac);
    text-transform: uppercase;
  }
`

const TwitchInfoContainer = styled.div`
  margin-bottom: 2rem;
`

const StyledMobileNavGroup = styled.div`
  .active-false {
    display: none;
  }

  div,
  div:first-of-type {
    border: 0px;
  }

  border-bottom: 1px solid var(--lilacLowOpacity);

  svg {
    vertical-align: middle;
    margin-left: 2px;
    margin-bottom: 5px;
    transform-origin: center;
    transform: ${({ active }) => (active ? 'rotate(0)' : 'rotate(180deg)')};
  }

  .nested-nav {
    padding-bottom: 4rem;
    a {
      display: block;
      padding-left: 1.5rem;
    }
  }
`

const NavGroup = ({ items, title }) => {
  const [isActive, setActive] = useState(false)

  return (
    <StyledMobileNavGroup>
      <NavItemStyle onClick={() => setActive(!isActive)}>
        <H3>
          {title}{' '}
          <span>
            <svg width="10" height="6" viewBox="0 0 10 6" fill="none">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M-5.24642e-08 5.39988C-6.58916e-08 5.24629 0.0544433 5.0927 0.162775 4.9757L4.60716 0.175941C4.82438 -0.0586467 5.17548 -0.0586467 5.3927 0.175941L9.83709 4.9757C10.0543 5.21029 10.0543 5.58947 9.83709 5.82406C9.61987 6.05865 9.26876 6.05865 9.05154 5.82406L4.99993 1.44848L0.94832 5.82406C0.731102 6.05865 0.379994 6.05865 0.162775 5.82406C0.0544433 5.70707 -3.90367e-08 5.55347 -5.24642e-08 5.39988Z"
                fill="#C1A7E6"
              />
            </svg>
          </span>
        </H3>
      </NavItemStyle>

      <div className={`nested-nav active-${isActive}`}>
        {items.map(link => (
          <NavItemStyle key={link.title}>
            <Link to={link.url}>
              <H3 uppercase color="var(--lilac)">
                {link.title}
              </H3>
            </Link>
          </NavItemStyle>
        ))}
      </div>
    </StyledMobileNavGroup>
  )
}

// const NavItem = ({ title, toggleClosedState, routeToHomepage }) => {
//   const scroll = () => {
//     toggleClosedState()
//     window.scrollTo(0, document.getElementById(title).offsetTop)
//   }

//   const readableTitle = title.replace("-", " ");

//   if (routeToHomepage) {
//     return <NavItemStyle>
//       <Link to={`/#${title}`}><H3 uppercase color='var(--lilac)'>{readableTitle}</H3></Link>
//     </NavItemStyle>
//   } else {
//     return <NavItemStyle onClick={scroll}>
//       <H3 uppercase color='var(--lilac)'>{readableTitle}</H3>
//     </NavItemStyle>
//   }
// }

const MobileHeader = ({ routeToHomepage, showBg }) => {
  const [closed, setClosed] = useState(true)

  const toggleClosedState = () => {
    setClosed(!closed)
  }

  const close = () => {
    setClosed(true)
  }

  return (
    <>
      {closed && (
        <Toggle
          showBg={showBg}
          routeToHomepage={routeToHomepage}
          toggleClosedState={toggleClosedState}
          closed={closed}
        />
      )}

      <ReachDialog aria-label="Dialog" isOpen={!closed} onDismiss={close}>
        {!closed && (
          <Toggle
            key={'x'}
            showBg={showBg}
            routeToHomepage={routeToHomepage}
            toggleClosedState={toggleClosedState}
            closed={closed}
          />
        )}

        <Header closed={closed}>
          <NavItemStyle>
            <Link onClick={toggleClosedState} to="/">
              <H3 uppercase color="var(--lilac)">
                Home
              </H3>
            </Link>
          </NavItemStyle>

          <NavItemStyle>
            <Link to="/how-to-play">
              <H3 uppercase color="var(--lilac)">
                How to play
              </H3>
            </Link>
          </NavItemStyle>

          <NavItemStyle>
            <Link to="/get-started">
              <H3 uppercase color="var(--lilac)">
                Get Started
              </H3>
            </Link>
          </NavItemStyle>

          <NavItemStyle>
            <Link
              to="https://play.skyweaver.net/market/cards/buy"
              target="_blank"
            >
              <H3 uppercase color="var(--lilac)">
                Cards
              </H3>
            </Link>
          </NavItemStyle>

          <NavItemStyle>
            <Link to="/news">
              <H3 uppercase color="var(--lilac)">
                News
              </H3>
            </Link>
          </NavItemStyle>

          {/* <CommuntyMobileNavGroup /> */}

          <NavGroup title="Community" items={communityNavLinks} />

          <NavItemStyle>
            <Link to="/lore">
              <H3 uppercase color="var(--lilac)">
                Lore
              </H3>
            </Link>
          </NavItemStyle>

          <NavGroup title="Media" items={mediaNavLinks} />

          <NavItemStyle>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://support.skyweaver.net"
            >
              <H3 uppercase color="var(--lilac)">
                Support
              </H3>
            </a>
          </NavItemStyle>


          <NavGroup title="Expansions" items={expansionNavLinks} />

          {/*<NavItemStyle>*/}
          {/*<Link to='/faq'><H3 uppercase color='var(--lilac)'>FAQ</H3></Link>*/}
          {/*</NavItemStyle>*/}

          {/* <PlayButton /> */}
          <JoinDiscord />

          <TwitchInfoContainer>
            <TwitchInfo />
          </TwitchInfoContainer>

          <LeftContent />
        </Header>
      </ReachDialog>
    </>
  )
}

export default MobileHeader
