import React from "react";
import styled from "styled-components";

import SocialMedia from "../SocialMedia";
import { P } from "../Typography";
import Image from "../Image";

const Section = styled.div`
  width: 100%;
  text-align: center;
  padding-bottom: 1rem;
  color: var(--lilac);
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 1) 90%,
    rgba(0, 0, 0, 0) 100%
  );
  margin-top: 4rem;

  p {
    font-size: 0.9rem;
  }

  .social-media {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding: 1rem;

  
  }

  @media (max-width: 750px) {
    .social-media {
      .link {
        width: 1.7rem;
        margin: 0 0.5rem;
      }
    }
  }
`;

const Horizon = styled.div`
  width: 11rem;
  display: inline-block;
  margin-bottom: 1rem;
  margin-top: 1rem;
`;

const Fan = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 2rem;
  margin-top: -5rem;

  &::after {
    content: " ";
    position: absolute;
    bottom: -1%;
    left: 0;
    height: 40%;
    width: 100%;
    z-index: 21;
    background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  }
`;

const MobileFooter = ({ fan = true, className }) => (
  <Section className={className}>
    {fan ? (
      <Fan id="mobile-fan">
        <Image src="/images/6-early/fan.png" />
      </Fan>
    ) : null}

    <SocialMedia />

    <a href="mailto:hello@skyweaver.net">
      <P color="var(--lilac)">hello@skyweaver.net</P>
    </a>

    <a style={{
      display: "block",
      marginBottom: "1rem"
    }} href="/terms-of-service">
      <P color="var(--lilac)" style={{ display: "inline", padding: "0.25rem 1rem" }}>Terms</P>
    </a>

    <a style={{
      display: "block",
      marginBottom: "1rem"
    }} href="https://horizon.io/privacy">
      <P style={{ display: "inline", padding: "1rem" }} color="var(--lilac)">Privacy</P>
    </a>

    <a
      href="https://horizon.io"
      target="_blank"
      rel="noopener noreferrer"
    >
      <Horizon>
        <Image src="/images/horizon.png" />
      </Horizon>

      <P color="#fff">
        © {new Date().getFullYear()} Horizon Blockchain Games Inc. All rights reserved.
      </P>
    </a>
  </Section>
);

export default MobileFooter