import React from "react"
import styled from 'styled-components'

export const Button = styled.span`
  font-family: var(--font);
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.01em;
  display: block;
  color: ${({ color }) => color ? color : 'var(--lilac)'};
  transition: 0.3s ease-out color, 0.3s ease-out opacity;

  @media (max-width: 650px) {
    font-size: 1.1rem;
  }
`

export const H1 = styled.h1`
  font-family: var(--font);
  font-size: 1.4em;
  letter-spacing: 0.025rem;
  color: ${({ color }) => color ? color : 'var(--lilac)'};
`

export const H2 = styled.h2`
  &, h2 {
    font-size: ${({ size }) => size ? size : '3.6rem'};
    line-height: 1.1;
    font-family: var(--condensedFont);
    margin-bottom: 1rem;
    font-weight: 600;
    text-transform: uppercase;
    color: ${({ color }) => color ? color : 'var(--lilac)'};
    ${({ uppercase }) => uppercase && `text-transform: uppercase`};

    @media (max-width: 1024px) {
      font-size: ${({ size }) => size ? size : '2.9rem'};

    }

    @media (max-width: 800px) {
      font-size: 2.4rem;
      font-size: ${({ size }) => size ? size : '2.4rem'};
    }

    @media (max-width: 750px) {
      font-size: 2.6rem;
      font-size: ${({ size }) => size ? size : '2.6rem'};
    }
  }
`

export const H3 = styled.h3`
  color: #fff;
  font-family: var(--font);
  letter-spacing: 0.01rem;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.4;
`

export const P = styled.p`
  color: ${({ color }) => color ? color : '#fff'};
  font-family: var(--font);
  letter-spacing: 0.01em;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.4;
  margin-bottom: 1.25rem;
  line-height: 1.5;

  & b {font-weight: 600; color: var(--lilac)}

  a:link {color: var(--lilac)}

  @media (max-width: 768px) {
    font-size: 1.4rem;
  }
`

export const Sub = styled.p`
  font-family: var(--font);
  line-height: 1.3;
  letter-spacing: 0.02em;
  font-size: 0.96rem;
  color: var(--lilac);
`

export const SubLarge = styled.p`
  font-family: var(--font);
  line-height: 1.3;
  letter-spacing: 0.02em;
  font-size: 1.2rem;
  text-transform: uppercase;
  color: var(--lilac);
  margin-bottom:0.5rem;

`

export const CheckMark = ({ children, style }) => (
  <div style={{ marginBottom: "0.8rem", ...style }}><SubLarge style={{ ...style }}><span>
    <svg style={{ marginRight: "0.5em" }} width="16" height="12" viewBox="0 0 16 12" fill="none">
      <path d="M1.0003 5.51819L4.98201 10.0365L14.0186 1" stroke="#C4ADEE" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  </span>{children}</SubLarge></div>
)

export const SubCondensed = styled.p`
  font-family: var(--condensedFont);
  line-height: 1.3;
  letter-spacing: 0.02em;
  font-weight: 500;
  font-size: 0.96rem;
  color: var(--lilac);
`