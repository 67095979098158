import React, { Component } from "react";
import styled from "styled-components";

import LeftContent from "./LeftContent";
import Fan from "./Fan";
import RightContent from "./RightContent";

import MobileFooter from "./MobileFooter";

const Section = styled.footer`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: -12.5rem;
  padding: 1.5rem;
  z-index: 5;
  position: absolute;
  bottom: ${({ bottom }) => (bottom ? bottom : "0")};
  left: 0;
  width: 100%;

  a {
    transition: 0.3s ease-out opacity;
    &:hover {
      opacity: 0.5;
    }
  }

  @media (max-width: 750px) {
    display: none;

    flex-wrap: wrap;
    width: 100%;
    position: relative;
    margin-top: 0;
    padding: 0;
    padding-bottom: 1rem;
    background: linear-gradient(to bottom, rgba(21, 15, 40, 0) 5%, #150f28 50%);
  }
`;

const DesktopFooter = ({ bottom, className }) => (
  <Section className={className} bottom={bottom}>
    <LeftContent />
    <Fan />
    <RightContent />
  </Section>
);

export default class Footer extends Component {
  render() {
    const {
      bottom = false,
      mobile = false,
      fan = true,
      className
    } = this.props;
    return (
      <>
        {this.props.windowWidth < 750 || mobile ? (
          <MobileFooter fan={fan} className={className} />
        ) : (
          <DesktopFooter className={className} bottom={bottom} />
        )}
      </>
    );
  }
}
