import React from 'react'
import styled from 'styled-components'
import { Link } from "gatsby"

import { OpenWithBg, CloseWithBg, Logo, PlayButtonSvg } from './Icons'

const PlayButton = styled.a`
  position: fixed;
  top: 0.9rem;
  left: 50%;
  z-index: 56;

  transition: 0.3s ease-out transform;
  transform: translateY(-10rem) translateX(-50%);

  svg {
    height: 3.56rem;
    cursor: pointer;
    fill: #fff;
  }

  @media (max-width: 1000px) {
    transform: translateY(0) translateX(-50%);
  }
`

const Icon = styled.div`
  position: fixed;
  top: 0.9rem;
  right: 1rem;
  z-index: 56;
  transition: 0.3s ease-out transform;
  transform: translateY(-10rem);

  svg {
    height: 3.56rem;
    cursor: pointer;
    fill: #fff;
  }

  a svg {
    height: 3.56rem;
  }

  @media (max-width: 1000px) {
    transform: translateY(0);
  }
`

const Div = styled.div`
  width: 100%;
  height: 5.4rem;
  position: fixed;top:0;left:0;
  background: var(--background);
  border-bottom: 1px solid var(--darkBorder);
  z-index: 56;
  transform: translateY(-10rem);
  transition: 0.3s ease-out transform, 0.3s ease-out background, 0.3s ease-out border-color;
  
  @media (max-width: 1000px) {
    transform: translateY(0);
  }
`

const Toggle = ({ closed, showBg, toggleClosedState, routeToHomepage = false }) =>
  <>
    <Div showBg={showBg || !closed} />
    <Icon style={{
      left: "1rem",
      right: "unset"
    }}>
      <Link to="/">
        <Logo />
      </Link>
    </Icon>

    <PlayButton href="https://play.skyweaver.net">
      <PlayButtonSvg />
    </PlayButton>

    <Icon onClick={toggleClosedState}>
      {closed ? <OpenWithBg /> : <CloseWithBg />}
    </Icon>
  </>

export default Toggle