import React from "react";
import styled from "styled-components";
import HeaderButton from "./HeaderButton";

const Span = styled.span`
  width: 1rem;
  // margin-right: 0.5rem;
  display: inline-block;
  vertical-align: middle;
  svg {
    width: 100%;
    fill: var(--lilac);
    transition: 0.3s ease-out fill;
  }
`;
const Text = styled.span`
  vertical-align: middle;
  margin-left: 1rem;
  display: none;
  transform: translateY(-0.16rem);
  transition: 0.3s ease-out color;

  @media (max-width: 700px) {
    display: inline-block;
  }
`;

const DiscordHeaderButton = styled(HeaderButton)`
  // margin-right: 10px;
`;

const DiscordLogo = () => (
  <svg viewBox="0 0 22 25" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.3132 10.4875c-.7167 0-1.2824.625-1.2824 1.3876 0 .7624.5784 1.3874 1.2824 1.3874.7165 0 1.2823-.625 1.2823-1.3874 0-.7626-.5658-1.3876-1.2823-1.3876m-4.5887 0c-.7165 0-1.2822.625-1.2822 1.3876 0 .7624.5782 1.3874 1.2822 1.3874.7166 0 1.2823-.625 1.2823-1.3874.0127-.7626-.5657-1.3876-1.2823-1.3876m5.8458 5.8375s-.4526-.5375-.8297-1.0125c1.6468-.4625 2.2754-1.4875 2.2754-1.4875-.5155.3375-1.0057.575-1.4457.7375-.6287.2625-1.232.4375-1.8228.5375-1.207.225-2.3132.1625-3.256-.0125-.7167-.1375-1.3327-.3375-1.848-.5375-.2892-.1125-.6035-.25-.9178-.425-.0377-.025-.0754-.0375-.1131-.0625a.1737.1737 0 0 1-.0503-.0374c-.2263-.125-.352-.2126-.352-.2126s.6034 1 2.2 1.475c-.3771.475-.8423 1.0375-.8423 1.0375-2.7783-.0874-3.8343-1.9-3.8343-1.9 0-4.025 1.8103-7.2875 1.8103-7.2875 1.8103-1.35 3.5325-1.3125 3.5325-1.3125l.1258.15c-2.2628.65-3.3063 1.6375-3.3063 1.6375s.2766-.15.7417-.3625c1.3452-.5875 2.4137-.75 2.8537-.7875.0754-.0124.1383-.025.2138-.025.7668-.1 1.6342-.125 2.5393-.025 1.1944.1375 2.4767.4875 3.7841 1.2 0 0-.9931-.9375-3.1303-1.5875l.176-.2s1.7223-.0374 3.5325 1.3125c0 0 1.8104 3.2625 1.8104 7.2875 0 0-1.0687 1.8126-3.847 1.9M19.423 0H2.5772C1.1566 0 0 1.15 0 2.575v16.9C0 20.9 1.1566 22.05 2.5772 22.05h14.256l-.6664-2.3125 1.6092 1.4876 1.5212 1.4L22 25V2.575C22 1.15 20.8435 0 19.423 0"
      fillRule="evenodd"
    />
  </svg>
);

const JoinDiscord = ({ content = "Discord" }) => (
  <a target="_blank" rel="noopener noreferrer" href="https://skyweaver.chat">
    <DiscordHeaderButton className="join-discord">
      <Span className="discord-logo">
        <DiscordLogo />
      </Span>

      <Text>{content}</Text>
    </DiscordHeaderButton>
  </a>
);

export default JoinDiscord