import React from "react";
import styled from "styled-components";
import { Button } from "../Typography";

const HeaderButtonContainer = styled.div`
  padding: 0.75rem 1rem;
  padding-bottom: 0.65rem;
  border-radius: 0.5rem;
  border: 1px solid var(--lilac);
  vertical-align: center;
  transition: 0.3s ease-out background-color;

  &:hover {
    background-color: var(--lilac);
    span {
      color: var(--dark);
    }
    svg {
      fill: var(--dark);
    }
  }

  @media (max-width: 750px) {
    width: 100%;
  }
`;

const HeaderButton = ({ children, ...props }) => (
  <HeaderButtonContainer {...props}>
    <Button>{children}</Button>
  </HeaderButtonContainer>
);

export default HeaderButton;
