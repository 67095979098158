import React, { useState, useEffect } from "react";
import HeaderButton from "./HeaderButton";
import styled from "styled-components";
import { stringify } from "querystring";

const Text = styled.span`
  color: var(--lilac);
  vertical-align: middle;
  position: absolute;
  top: 35%;
  left: 2.8rem;
  font-size: 1.5rem;
  transform: translateY(-50%);
  display: none;

  @media (max-width: 700px) {
    display: inline-block;
  }

`

const fetchTwitchInfo = async () => {
  const fetchStreams = async () => {
    const qs = stringify({
      game_id: "512913"
    });

    const res = await fetch(`https://api.twitch.tv/helix/videos?${qs}`, {
      headers: {
        'Client-ID': 'us2bsmweqgy0jkt13gtwvtkxszqdno'
      }
    });


    const json = await res.json();

    return json.data && json.data.length;
  };

  const streams = await fetchStreams()

  return {
    streams
  };
};

const TwitchInfo = () => {
  const [state, setState] = useState({
    streams: 0,
    loading: true
  });

  useEffect(() => {
    const init = async () => {
      const { streams } = await fetchTwitchInfo();

      setState({
        streams,
        loading: false
      });
    };

    init();
  }, []);

  return (
    <Container>
      {state.streams !== 0 ?
        <a
          href="https://www.twitch.tv/directory/game/Skyweaver"
          target="_blank"
          rel="noopener noreferrer"
        >
          <StyledHeaderButton className="streamsButton">
            <ButtonContainer>
              <GlitchContainer>

                <Glitch size={14} />
                <Text>Twitch</Text>

              </GlitchContainer>

              <LiveDot />
            </ButtonContainer>
          </StyledHeaderButton>
        </a>
        :
        <a
          href="https://www.twitch.tv/directory/game/Skyweaver/videos/all"
          target="_blank"
          rel="noopener noreferrer"
        >
          <StyledHeaderButton className="vodsButton">
            <ButtonContainer>
              <GlitchContainer>
                <Glitch size={14} />
                <Text>Twitch</Text>

              </GlitchContainer>
            </ButtonContainer>
          </StyledHeaderButton>
        </a>
      }
    </Container>
  );
};

const Glitch = ({ size }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 134" width={size}>
      <path
        fillRule="evenodd"
        d="M89,77l-9,23v94h32v17h18l17-17h26l35-35V77H89Zm107,76-20,20H144l-17,17V173H100V89h96v64Zm-20-41v35H164V112h12Zm-32,0v35H132V112h12Z"
        transform="translate(-80 -77)"
      />
    </svg>
  );
};

const LiveDot = styled.div`
  width: 12px;
  height: 12px;
  margin-left: 6px;
  border-radius: 8px;
  border: 2px solid black;
  background-color: #65fd00;
  position: absolute;
  top: -4px;
  right: -8px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;

  .fullDescription {
    display: none;
  }

  @media (max-width: 750px) {
    a {width: 100%;}
    
    .vodsButton,
    .streamsButton {
      flex: 1;
    }
  }

  @media (max-width: 1000px) and (min-width: 750px) {
    .vodsButton {
      display: none;
    }
  }

  @media (min-width: 1100px) {
    .fullDescription {
      display: initial;
    }

    .shortDescription {
      display: none;
    }
  }

  @media (max-width: 750px) {
    .fullDescription,
    .shortDescription {
      font-size: 1.5rem;
    }
  }

  
`;

const GlitchContainer = styled.div`
  svg {
    fill: var(--lilac);
  }

  top: 2px;
  position: relative;
  align-self: flex-start;
  
  @media (max-width: 750px) {
    svg {width: 1.8rem}
  }
`;

const ButtonContainer = styled.div`
  position: relative;
`;

const StyledHeaderButton = styled(HeaderButton)`
  margin-right: 10px;
  height: 90%;
  cursor: pointer;

  @media (max-width: 750px) {
    padding-bottom: 1rem;
  }
`;

export default TwitchInfo;