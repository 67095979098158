import React from "react"
import styled from "styled-components"

const OutterButton = styled.div`
	transition: 0.3s ease-in-out filter;
	clip-path: polygon(0% 0%, calc(100% - 8px) 0%, 100% 8px, 100% 100%, 8px 100%, 0% calc(100% - 8px));
	font-family: var(--condensedFont);
	font-size: 1.2rem;
	font-weight: 500;
	line-height: 1;
	text-transform: uppercase;
	color: white;
	background: #86FBB4;
	display:inline-block;
	width: 100%;
	position: relative;
	padding: 0.82rem 2rem;
`

const Link = styled.a`
	position: relative;	
	display:inline-block;
	vertical-align: middle;
	filter: drop-shadow(0px 0px 10px #4AD578) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) drop-shadow(0px 0px 20px #0C061E);
	max-width: 30rem;
	width: 100%;
	margin: auto;
	margin-left: 0.8rem;
	margin-top: 0px;


	&:hover {
		.inner-button {
			background: linear-gradient(180deg, #29BFFF 46.09%, #069FE1 46.78%);
			background: linear-gradient(#44CC7A 0%, #44CC7A 29%, #44CC7A 49%, #38B168 50%, #38B168 100%);
		}
	}
`

const Button = styled.div`
	z-index: 4;
	position: relative;
`

const InnerButton = styled.div`
	position: absolute;
	top: 1px;left:1px;
	width: calc(100% - 2px);
	height: calc(100% - 2px);

	clip-path: polygon(0% 0%, calc(100% - 8px) 0%, 100% 8px, 100% 100%, 8px 100%, 0% calc(100% - 8px));

	background: linear-gradient(#3EB06B 0%, #3EB06B 29%, #3EB06B 49%, #309D5B 50%, #309D5B 100%);

	pointer-events: none;
`


const PlayFreeButton = ({
	color,
	label = "Play Free"
}) => {
	return (
		<Link href="https://play.skyweaver.net" target="_blank" rel="noopener noreferrer">
			<OutterButton>
				<Button>
					{label}
				</Button>
				<InnerButton className="inner-button" />
			</OutterButton>
		</Link>

	)
}

export default PlayFreeButton