import React, { PureComponent } from 'react'
import styled from 'styled-components'

import Image from '../Image'

const Section = styled.section`
  max-width: 50rem;
  width: calc(100% - 30rem);
  transition: 0.3s ease-out transform;
  position: absolute;
  bottom: 0;
  left: 50%; transform: translateX(-50%);

  @media (max-width: 750px) {
    position: relative;
    width: 100%;
    order: 1;
    padding-bottom: 2rem;

    &::after {
      content: ' ';
      position: absolute;
      bottom: 0;
      left: 0;
      height: 100%;
      width: 100%;
      padding-bottom: 0;
      align-self: flex-end;
      background: linear-gradient(to bottom, rgba(21, 15, 40, 0) 0%, #150f28 80%);  
    }
  }
`

export default class Fan extends PureComponent {
  render () {
    return (
      <Section>
        <Image src='/images/6-early/fan.png' />
      </Section>
    )
  }
}